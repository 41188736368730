import React, { useEffect, useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Tooltip } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { pathTo } from '../../../lib/utils';
import { FontWeight, IconsType } from '../../../lib/constants/enums';
import { AuthSelector } from '../../../redux/auth/authSelector';
import { setLoginActionCreator } from '../../../redux/auth/actions';
import style from './style.module.scss';
import Button from '../../Button/Button';
import { AdaptiveLayout } from '../../Layouts/Layouts';
import AuthForm from '../AuthForm/AuthForm';

interface AuthState {
  [key: string]: boolean;
}

const SignIn: React.FC = () => {
  const { loginWithRedirect, isLoading, user } = useAuth0();
  const { t } = useTranslation();
  const { user: userInfo } = useSelector(AuthSelector);
  const dispatch = useDispatch();

  const [auth, setAuth] = useState<AuthState>({ modal: false, render: false });

  useEffect(() => {
    if (user && userInfo === null) {
      dispatch(setLoginActionCreator(user));
    }
  }, [user]);

  return (
    <>
      {auth ? <AuthForm auth={auth} setAuth={setAuth} /> : false}
      <div className={style.wrapper}>
        {userInfo === null ? (
          <>
            <a href="https://club.goslim.pro/accounts/login/" target="_blank" rel="noreferrer">
              <AdaptiveLayout onDesktop onTablet onMobile>
                <Button
                  active
                  width={150}
                  // onClick={() => setAuth({ modal: true, render: true })}
                  title={t('header:auth.logIn')}
                  fontSize={16}
                  fontWeight={FontWeight.medium}
                  icon={IconsType.user}
                />
              </AdaptiveLayout>
            </a>
            {/*<a href="https://club.goslim.pro/accounts/login/" target="_blank" rel="noreferrer">*/}
            {/*  <AdaptiveLayout onMobile>*/}
            {/*    <div*/}
            {/*      className={style.authBtn}*/}
            {/*      // onClick={() => setAuth({ modal: true, render: true })}*/}
            {/*    >*/}
            {/*      <UserOutlined />*/}
            {/*    </div>*/}
            {/*  </AdaptiveLayout>*/}
            {/*</a>*/}
          </>
        ) : (
          <Tooltip placement="bottomRight" title={t('header:personalArea')}>
            <div className={style.userProfile}>
              <div className={style.hoverBg} />
              <Link to={pathTo('/user-profile')}>
                <Avatar size="large" className={style.avatar} src={userInfo.picture || ''} icon={<UserOutlined />} />
              </Link>
            </div>
          </Tooltip>
        )}
      </div>
    </>
  );
};

export default SignIn;
